@import url("https://fonts.googleapis.com/css?family=Stint+Ultra+Expanded:400,700,900");

:root {
  /* --blue: #172d40; */ /* rgb(23,45,64) */
  /* --light-blue: var(--very-dark-blue); */ /* #396992;  rgb(57,105,146) */  
  /* --very-light-blue: #7cb3e2; */
  /* --blue-greeny: var(--dark-green);  *//* #16738a; rgb(22, 115, 138)   */
  /* --light-blue-greeny: #16738a61; */
  /* --magenta: var(--main-magenta); */ /*rgb(249, 13, 74) */
  /* --light-magenta: #fb6e92; */
  /* --dark-grey: #4d4c50; */ /*rgb(77,76,80) */
  /* --very-dark-grey: #3b3b3b; */
  /* --grey: #6a696f; */
  /* --light-grey: #9a999e; */ /*rgb(154,153,158)*/
  /* --very-light-grey: var(--very-light-grey); */
  --cream: #e7e7e7; /* rgb(231,231,231) */
  --green: var(--very-dark-green);
  --light-green: #81f368;
  --yellow: #ffa600;
  --light-yellow: #ffc862;
  --orange: #ff5900;
  --light-orange: #feac81;
  --light-red: #ffe6e6;
  --red: #a12e2c;
  --violet: #4e4e68;
  --light-violet: var(--middle-violet);
  --background-error: #ffdfde;
  --title: 24px;
  --subtitle: 18px;

  --main1: #102131;
  --main2: #3e4e5b;
  --main3: #b8bdc1;
  --secondary1: #d9256f;
  --secondary2: #df518b;
  --secondary3: #f2bed4;
  --complementary1: #7ad2c4;
  --complementary2: #93dbcf;
  --complementary3: #d6f0ed;

  /* Extras */
  --transparent-grey1: rgba(231, 231, 231, 0.4);
  --transparent-grey2: rgba(231, 231, 231, 0.6);
  --transparent-dark-grey: rgba(77, 76, 80, 0.2);
  --transparent-blue-greeny: rgba(23, 45, 64, 0.9);

  /* Nueva paleta */

  /* Main Color */
  --main-blue: #172d40;
  --very-dark-blue: #22435f;
  --dark-blue: #33648e;
  --middle-blue: #6399c7;
  --light-blue: #b1cce3;
  --very-light-blue: #e0ebf4;

  /* Error */
  --main-magenta: #f90d4a;
  --very-dark-magenta: #800323;
  --dark-magenta: #c00535;
  --middle-magenta: #fb6e92;
  --light-magenta: #fd9eb7;
  --very-light-magenta: #fecfdb;

  /* Success */
  --main-green: #34d4bf;
  --very-dark-green: #176d62;
  --dark-green: #23a392;
  --middle-green: #85e5d9;
  --light-green: #aeeee5;
  --very-light-green: #d6f6f2;

  /* Warning */
  --main-orange: #ff9800;
  --very-dark-orange: #b36a00;
  --dark-orange: #cc7a00;
  --middle-orange: #e68900;
  --light-orange: #ffbb99;
  --very-light-orange: #ffddcc;

  /* Info */
  --main-grey: #9a999e;
  --very-dark-grey: #4d4c50;
  --dark-grey: #737278;
  --middle-grey: #c2c2c5;
  --light-grey: #d7d6d8;
  --very-light-grey: #ebebec;

  /* Bluish-violet */
  --main-bluish-violet: #cccfd9;
  --very-dark-bluish-violet: #121318;
  --dark-bluish-violet: #2d303c;
  --middle-bluish-violet: #5a6179;
  --light-bluish-violet: #9096ac;
  --very-light-bluish-violet: #b4b8c7;

  /* Violet */
  --main-violet: #4e4e68;
  --very-dark-violet: #272734;
  --dark-violet: #3b3b4e;
  --middle-violet: #8f8fab;
  --light-violet: #b4b4c7;
  --very-light-violet: #dadae3;

  /* Yellow */
  --main-yellow: #ffc107;
  --very-dark-yellow: #b37700;
  --dark-yellow: #cc8f00;
  --middle-yellow: #e6a800;
  --light-yellow: #ffeb99;
  --very-light-yellow: #fff3cd;

  /* Bluish-green */
  --main-bluish-green: #16738a;
  --very-dark-bluish-green: #0b3a45;
  --dark-bluish-green: #105668;
  --middle-bluish-green: #4ac4e2;
  --light-bluish-green: #86d8ec;
  --very-light-bluish-green: #c3ebf5;

  /* Texts */
  --text-main: var(--main-blue);
  --text-secondary: var(--very-dark-blue);
  --text-selected: var(--main-green);
  --text-subtitle: var(--very-dark-blue);
  --text-success: var(--very-dark-green);
  --text-warning: var(--main-orange);
  --text-error: var(--main-magenta);
  --text-info: var(--very-dark-grey);
  --text-light: white;
  --text-disabled: var(--main-grey);

  /* Backgrounds */
  --bg-main: var(--main-blue);
  --bg-main-hover: var(--very-light-blue);
  --bg-secondary: var(--very-dark-blue);
  --bg-selected: var(--main-green);
  --bg-error: var(--main-magenta);
  --bg-error-hover: var(--middle-magenta);
  --bg-warning: var(--middle-orange);
  --bg-warning-hover: var(--very-light-orange);
  --bg-success: var(--dark-green);
  --bg-success-hover: var(--light-green);
  --bg-info: var(--main-violet);
  --bg-info-hover: var(--very-light-violet);
  --bg-light: var(--very-light-grey);
  --bg-neutral: var(--dark-bluish-violet);
  --bg-neutral-hover: var(--dark-grey);
  --bg-disabled: var(--main-grey);

  /* Tables */  
  
  --table-header: var(--very-dark-blue);
  --table-row: var(--very-light-grey);

  /* Buttons */
  --button-main: var(--main-blue);
  --button-main-hover: var(--very-light-blue);
  --button-cancel: var(--very-dark-grey);
  --button-cancel-hover: var(--very-light-grey);
  --button-success: var(--dark-green);
  --button-success-hover: var(--light-green);
  --button-warning: var(--middle-orange);
  --button-warning-hover: var(--very-light-orange);
  --button-error: var(--main-magenta);
  --button-error-hover: var(--very-light-magenta);
  --button-info: var(--main-violet);
  --button-info-hover: var(--very-light-violet);
  --button-delete: var(--dark-grey);
  --button-delete-hover: var(--very-light-grey);
  --button-disabled: var(--main-grey);

  /* Icons */
  --icon-main: var(--dark-blue);
  --icon-secondary: var(--very-dark-blue);
  --icon-success: var(--dark-green);
  --icon-warning: var(--middle-orange);
  --icon-error: var(--main-magenta);
  --icon-info: var(--middle-bluish-violet);
  --icon-delete: var(--dark-grey);
  --icon-disabled: var(--main-grey);

  /* Charts, elements */
  --chart-1: var(--dark-blue);
  --chart-2: var(--main-green);
  --chart-3: var(--middle-magenta);
  --chart-4: var(--main-bluish-violet);
  --chart-5: var(--main-violet);
  --chart-6: var(--main-bluish-green);
  --chart-7: var(--light-orange);
  --chart-8: var(--light-bluish-violet);
  --chart-9: var(--light-violet);
  --chart-10: var(--light-bluish-green);
  --chart-11: var(--middle-yellow);
  --chart-12: var(--middle-grey);
  --chart-13: var(--middle-blue);
  --chart-14: var(--middle-green);
  --chart-15: var(--light-magenta);
  --chart-16: var(--middle-orange);
  --chart-17: var(--middle-violet);
  --chart-18: var(--middle-bluish-green);
  --chart-19: var(--middle-bluish-violet);
  --chart-20: var(--very-dark-green);
  --chart-21: var(--very-dark-magenta);
  --chart-22: var(--light-blue);
  --chart-23: var(--dark-bluish-green);
}

* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.row-with-errors {
  background-color: var(--very-light-grey);
  color: var(--dark-grey);
}

.row-with-errors:hover {
  background-color: var(--very-light-grey) !important;
  color: var(--dark-grey) !important;
}

.row-with-errors input[type="checkbox"] {
  pointer-events: none; /* Deshabilita el checkbox */
}

.row-with-errors input[type="checkbox"]:hover {
  cursor: none !important;
  pointer-events: none !important;
}
